import {
  EventCompetitionType,
  Ground,
  EventHandicapMode,
  EventsWindSpeed,
  Weather,
  WindDirection,
  TeeType,
  GimmeDistance,
} from "types/enums";

export function titleCase(str: string) {
  return str
    .toString()
    .replaceAll("_", " ")
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
}

export function roundConfigEnumToReactSelectOptions(enumme: any) {
  const arrayObjects = [];

  for (const [propertyKey, propertyValue] of Object.entries<string | number>(
    enumme
  )) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    arrayObjects.push({
      value: propertyValue,
      label: titleCase(propertyKey as string),
    });
  }

  return arrayObjects;
}

export const configEnumsAsSelectOptions = {
  eventCompetitionType:
    roundConfigEnumToReactSelectOptions(EventCompetitionType),
  ground: roundConfigEnumToReactSelectOptions(Ground),
  windDirection: roundConfigEnumToReactSelectOptions(WindDirection),
  eventsWindSpeed: roundConfigEnumToReactSelectOptions(EventsWindSpeed),
  eventsHandicapMode: roundConfigEnumToReactSelectOptions(EventHandicapMode),
  weather: roundConfigEnumToReactSelectOptions(Weather),
  teeType: roundConfigEnumToReactSelectOptions(TeeType),
  gimmeDistance: roundConfigEnumToReactSelectOptions(GimmeDistance),
};
