export enum AutoConcede {
  OFF = 0,
  DOUBLE_PAR = 1,
  TEN_STROKES = 2,
  BOGEY = 3,
  DOUBLE_BOGEY = 4,
  TRIPLE_BOGEY = 5,
}
export enum AutoContinue {
  OFF = 0,
  ONE_SECOND = 1,
  FOUR_SECONDS = 4,
  SEVEN_SECONDS = 7,
  TEN_SECONDS = 10,
  FIFTEEN_SECONDS = 15,
  INSTANT = -1,
}
export enum AutoGimme {
  OFF = 0,
  ONESTROKE = 1,
  TWOSTROKE = 2,
  THREESTROKE = 3,
}

export enum ExitSimType {
  EXIT = 0,
  SHUTDOWN = 1,
  RESTART = 2,
}
export enum FacilityMessageType {
  PlayerLogin = 0,
  PlayerLogout = 1,
  LockerToken = 2,
}
export enum FacilityResponseMessageType {
  PlayerLoginReply = 0,
  PlayerLogoutReply = 1,
}
export enum GameType {
  RANGE = 0,
  ROUND = 1,
}
export enum Gender {
  MALE = 0,
  FEMALE = 1,
}
export enum GimmeDistance {
  OFF = 0,
  "3FT" = 3,
  "6FT" = 6,
  "9FT" = 9,
  "12FT" = 12,
}
export enum HandicapMode {
  ABSOLUTE = 0,
  RELATIVE = 1,
  OFF = 2,
}
export enum HandicapPercentage {
  ONE_HUNDRED = 100,
  NINTY = 90,
  EIGHTY = 80,
  SEVENTY = 70,
}
export enum PinType {
  NOVICE = 0,
  INTERMEDIATE = 1,
  ADVANCED = 2,
}
export enum Ranges {
  MOUNTAIN_VENUE = 1,
  TROPICAL = 12,
  CANYON = 18,
  WATERFALLS = 13,
  TARGET_GREEN = 16,
  LONG_DRIVE_GRID = 14,
  LONG_FAIRWAY = 15,
  SAND_20_YDS = 11,
  CHIP_15_YDS = 9,
  CHIP_40_YDS = 10,
  DOWNHILL_PUTT = 6,
  UPHILL_PUTT = 5,
  PUTT_RIGHT_BRK = 7,
  PUTT_LEFT_BRK = 8,
  PUTT_5_FT = 2,
  PUTT_15_FT = 3,
  PUTT_45_FT = 4,
}
export enum RoundStatus {
  Begin = 0,
  InProgress = 1,
  End = 2,
}
export enum ScoringType {
  STROKE = 0,
  MATCH = 1,
  SCRAMBLE = 7,
  STABLEFORD = 11,
}
export enum ScoringTypes {
  SCORING_STROKE = 0,
  SCORING_MATCH = 1,
  SCORING_SKINS = 3,
  SCORING_SCRAMBLE = 7,
  SCORING_BINGO = 8,
  SCORING_LONG_DRIVE = 9,
  SCORING_CLOSEST_TO_PIN = 10,
  SCORING_STABLEFORD = 11,
  SCORING_STROKE_CUSTOM = 12,
  SCORING_STROKE_DRILLS = 13,
}
export enum ServiceRequestType {
  CC_REQUEST_FOOD = 1,
  CC_REQUEST_HELP = 2,
  CC_REQUEST_STUCK = 3,
}
export enum SimStatus {
  STATUS_SIM_OFF = 0,
  STATUS_SIM_START = 1,
  STATUS_SETUP_ENTER = 2,
  STATUS_SETUP_IDLE = 3,
  STATUS_SETUP_EXIT = 4,
  STATUS_RANGE_ENTER = 5,
  STATUS_RANGE_SHOT = 6,
  STATUS_RANGE_IDLE = 7,
  STATUS_RANGE_EXIT = 8,
  STATUS_RND_START = 9,
  STATUS_RND_SHOT = 10,
  STATUS_RND_IDLE = 11,
  STATUS_RND_HOLE_END = 12,
  STATUS_RND_END = 13,
  STATUS_SIM_EXIT = 14,
  STATUS_TIMER_PAUSE = 15,
  STATUS_TIMER_UNPAUSE = 16,
}
export enum TimerStatus {
  ON = 0,
  OFF = 1,
  RUNNING = 2,
  PAUSED = 3,
  EXPIRED = 4,
}
export enum SimulatorMessageType {
  SIM_STATUS = 0,
  SIM_START_GAME = 1,
  SIM_END_GAME = 2,
  TIMER_CONTROL = 3,
  SIM_EXIT = 4,
  SEND_MESSAGE = 5,
  SCORECARD = 6,
  COURSE_LIST = 7,
  SERVICE_REQUEST = 8,
  MESSAGE_PROCESSED = 9,
  TIMER_UPDATE = 10,
  ROUND_BEGAN = 11,
  ROUND_UPDATE = 12,
  ROUND_ENDED = 13,
  ROUND_PLAYER_UPDATE = 14,
}
export enum SimulatorResponseMessageType {
  SIM_STATUS_REPLY = 0,
  SIM_START_GAME_REPLY = 1,
  SIM_END_GAME_REPLY = 2,
  TIMER_CONTROL_REPLY = 3,
  SIM_EXIT_REPLY = 4,
  SEND_MESSAGE_REPLY = 5,
  SCORECARD_REPLY = 6,
  COURSE_LIST_REPLY = 7,
  SERVICE_REQUEST_REPLY = 8,
  MESSAGE_PROCESSED_REPLY = 9,
  TIMER_UPDATE_REPLY = 10,
  ROUND_BEGAN_REPLY = 11,
  ROUND_UPDATE_REPLY = 12,
  ROUND_ENDED_REPLY = 13,
  ROUND_PLAYER_UPDATE_REPLY = 14,
}
export enum SwingMeterType {
  Default = 5,
}
export enum Team {
  TEAM1 = 1,
  TEAM2 = 2,
}
export enum TeeType {
  RED = "red",
  WHITE = "white",
  BLUE = "blue",
  BLACK = "black",
  GOLD = "gold"
}
export enum TimerAction {
  ADD_TIME = 0,
  PAUSE = 1,
  CLEAR_DISABLE = 2,
  UNPAUSE = 3,
  CLEAR_LOCK = 4,
}
export enum WindSpeed {
  CALM = 0,
  LIGHT = 1,
  MODERATE = 2,
  STRONG = 3,
  VERY_STRONG = 4
}

export enum EventCompetitionType {
  STROKE = 0,
  LONG_DRIVE = 9,
  CLOSEST_TO_PIN = 10,
}
export enum Ground {
  VERY_DRY = "very_dry",
  DRY = "dry",
  NORMAL = "normal",
  WET = "wet",
  VERY_WET = "very_wet",
}
export enum WindDirection {
  NORTH = "north",
  NORTHEAST = "northeast",
  EAST = "east",
  SOUTHEAST = "southeast",
  SOUTH = "south",
  SOUTHWEST = "southwest",
  WEST = "west",
  NORTHWEST = "northwest",
}
export enum Weather {
  SUNNY = "sunny",
  MOSTLY_SUNNY = "mostly_sunny",
  MOSTLY_CLOUDY = "mostly_cloudy",
  CLOUDY = "cloudy",
  FOG = "fog",
}
export enum EventsWindSpeed {
  CALM = "calm",
  LIGHT = "light",
  MODERATE = "moderate",
  STRONG = "strong",
  VERY_STRONG = "very_strong",
}
export enum EventHandicapMode {
  COURSE_HANDICAP = 0,
  OFF = 2,
}

export enum BootstrapVariant {
  SUCCESS = "Success",
  DANGER = "Danger",
  WARNING = "Warning",
}
